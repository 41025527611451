
























































































































import Vue from "vue";

import { getError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";
import { ErrorResult } from "@/utils/helpers";
import { apiAxios } from "@/store/apiAxios";
import { BIconEyeFill } from "bootstrap-vue";

export default Vue.extend({
  name: "ResetPasswordForm",
  components: { BIconEyeFill },
  data() {
    return {
      email: null,
      password: null,
      passwordConfirm: null,
      error: {} as ErrorResult,
      message: "",
      id: "",
      showNewPassword: false,
      showPassword: false,
      responsePassword: "",
    };
  },
  created() {
    this.id = this.$route.query.token.toString().split("+")[1];

    apiAxios.get("/api/userEmail/" + this.id).then((r) => {
      this.email = r.data;
    });
  },
  mounted() {
    this.$router.replace({
      name: "Reset Password",
      query: { token: this.$route.query.token.toString().split("+")[0] },
    });
  },
  methods: {
    resetPassword() {
      this.error = {};
      this.message = "";
      const logs = {
        email: this.email,
        type: "reset password",
        step: "reset password completed",
      };
      const payload = {
        email: this.email,
        password: this.password,
        password_confirmation: this.passwordConfirm,
        token: this.$route.query.token.toString(),
      };
      AuthService.resetPassword(payload)
        .then(() => {
          AuthService.addLog(logs),
            this.$router.push({
              name: "Login",
              params: { pass_reset: "reset" },
            });
        })
        .catch((error) => (this.error = getError(error)));
    },
  },
});
