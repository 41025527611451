






import Vue from "vue";
import AuthContainer from "@/components/auth/AuthContainer.vue";
import ResetPasswordForm from "@/components/auth/ResetPasswordForm.vue";

export default Vue.extend({
  name: "ResetPassword",
  components: {
    AuthContainer,
    ResetPasswordForm,
  },
});
